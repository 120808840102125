.single-services {
  .recent-projects {
    @media(max-width:1023px) {
      margin-top: 100px;
      padding-bottom: 249px;
    }
  }
}

.recent-projects {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: auto;
  padding: 80px;
  padding-bottom: 0;
  padding-top: 0;

  a.btn-mobile {
    display: none;

    @media(max-width:768px){
      display: flex;
      margin-top: 30px;
    }
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 59px;

    h2 {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 43px;
      color: #212E5E;
    }

    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      color: #E75E24;
      margin-bottom: 10px;
    }

    a {
      margin-left: 15px;
    }

    @media (max-width: 768px) {
      margin-bottom: 45px;

      h2 {
        font-size: 30px;
        line-height: 36px;
      }

      h3 {
        font-size: 15px;
        line-height: 20.43px;
      }

      a.btn-dsk {
        display: none;
      }
    }
  }

  .list-projects {
    display: flex;

    > li {
      flex-basis: 170px;
      max-width: 170px;
      height: 645px;
      transition: all 0.2s ease;
      margin-right: 20px;
      min-width: 170px;

      @media (max-width: 768px) {
        margin-right: 0;
      }

      @media (max-width: 560px) {
        height: 506px;
      }

      &:last-child {
        margin-right: 0;
      }

      .item-project {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #2D5397E5;
        cursor: pointer;

        .header {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(0deg, rgba(45, 83, 151, 0.9), rgba(45, 83, 151, 0.9));
          z-index: 2;
          transition: all 0.2s ease;

          &:hover {
            background: linear-gradient(180deg, rgba(249, 137, 61, 0.9) 0%, rgba(231, 94, 36, 0.9) 100%);
          }

          h2 {
            transform: rotate(-90deg) translateY(-100%);
            transform-origin: left;
            white-space: nowrap;
            position: absolute;
            bottom: 0;
            left: 100%;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            text-transform: capitalize;
            color: #FFFFFF;
          }
        }

        .info {
          position: absolute;
          bottom: 0;
          left: 0;
          opacity: 0;
          width: 100%;
          min-height: 200px;
          margin-top: auto;
          padding: 62px 100px 35px 35px;
          background: linear-gradient(180deg, rgba(33, 46, 94, 0) 0%, rgba(33, 46, 94, 0.5) 25.52%, rgba(33, 46, 94, 0.88) 100%);
          transition: all 0.6s ease 0.2s;

          h2 {
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #FFFFFF;
            margin-bottom: 10px;
          }

          p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #FFFFFF;
          }

          a {
            margin-top: 10px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #FFFFFF;
            display: flex;
            align-items: center;

            &::after {
              content: "+";
              font-size: 22px;
              width: 22px;
              height: 22px;
              color: #F9893D;
              margin-left: 10px;
              font-weight: bold;
              vertical-align: -3px;
              transition: all 0.5s;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:hover {
              &::after {
                background: #E75E24;
                color: #fff;
              }
            }
          }
        }
      }

      &.active {
        width: 100%;
        flex-basis: initial;
        max-width: initial;

        .header {
          width: 64px;
          background: #E75E24;

          &:hover {
            background: #E75E24;
          }
        }

        .info {
          opacity: 1;
        }
      }

      @media (max-width: 1200px) {
        padding: 0 5px;
      }
    }

    @media (max-width: 768px) {
      display: block;

      > li {
        display: inline-flex;
        width: 315px;
        max-width: 100%;
        padding: 0;

        &.active {
          width: 315px;
          max-width: 100%;
        }

        .item-project {
          .header {
            width: 50px;
            background: #E75E24;

            &:hover {
              background: #E75E24;
            }

            h2 {
              font-size: 20px;
              line-height: 27.24px;
            }
          }

          .info {
            opacity: 1;

            h2 {
              font-size: 16px;
              line-height: 22px;
            }

            p {
              font-size: 15px;
              line-height: 23px;
            }
          }
        }
      }
    }
  }

  .btn-orange {
    padding: 20px 30px;
    min-width: 153px;
    height: 56px;
    background: linear-gradient(270deg, #E75E24 0%, #F9893D 100%);
    border-radius: 30px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-left: auto;

    &:hover {
      background: linear-gradient(270deg, #F9893D 0%, #F9893D 100%);
    }
  }

  @media (max-width: 768px) {
    padding: 0 30px;

    .btn-mobile {
      display: flex;
      margin: auto;
    }

    .tns-outer .tns-nav {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 30px;

      button {
        width: 14px;
        height: 14px;
        background: #DAE2ED;
        margin-right: 10px;
        border-radius: 50%;
        transition: all 0.5s;

        &.tns-nav-active {
          background: #E75E24;
        }
      }
    }
  }
}